<template>
  <div class="product_oa_content">
    <div class="box_top">
      <Header class="page_hea" />
      <div class="top_tlite">
        <p class="title">
          蒜瓣OA
        </p>
        <p class="subtitle">
          高效办公的首选
        </p>
        <div
          class="btn"
          @click="openqq"
        >
          立即咨询
        </div>
      </div>
      <div class="top_img">
        <img
          v-lazy="oa_bg"
          alt=""
        >
      </div>
    </div>
    <div class="page_content">
      <div class="section">
        <p class="title">
          足够轻量，足够高效！
        </p>
        <p class="subtitle short">
          轻松办公，提高协同效率，办公效率，是我们的唯一目标。
        </p>
        <div class="content_box">
          <div class="fs">
            <div
              v-for="(item, index) in fs"
              :key="index"
            >
              <img
                :src="item.img"
                alt=""
              >
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="section blue">
        <p class="title">
          全方位多业务场景
        </p>
        <div class="content_box swiper">
          <el-carousel
            indicator-position="outside"
            :arrow="'never'"
            :interval="2000"
          >
            <el-carousel-item
              v-for="(item, index) in swiper"
              :key="index"
            >
              <img
                :src="item"
                alt=""
                style="width: 100%"
              >
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="section">
        <p class="title">
          核心功能
        </p>
        <div class="content_box">
          <div
            class="icons"
          >
            <div
              v-for="(item, index) in icon"
              :key="index"
              class="items"
            >
              <img
                :src="item.icon"
                alt=""
              >
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="section linear">
        <p class="title">
          扩展功能
        </p>
        <div class="content_box">
          <div
            class="kuozhan"
          >
            <div
              v-for="(item, index) in kuozhan"
              :key="index"
            >
              <img
                :src="item.img"
                alt=""
              >
              <p class="title">
                {{ item.title }}
              </p>
              <p class="subtitle">
                {{ item.subtitle }}
              </p>
            </div>
          </div>
          <btn
            class="ljsq"
            :content="'立即申请'"
            @click="openBut"
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import oa_bg from '@/assets/imgs/product/oa/oa_bg.png';
import oa1 from '@/assets/imgs/product/oa/oa1.png';
import oa2 from '@/assets/imgs/product/oa/oa2.png';
import oa3 from '@/assets/imgs/product/oa/oa3.png';
import oa4 from '@/assets/imgs/product/oa/oa4.png';
import oa5 from '@/assets/imgs/product/oa/oa5.png';
import tab1 from '@/assets/imgs/product/oa/tab1.png';
import tab2 from '@/assets/imgs/product/oa/tab2.png';
import tab3 from '@/assets/imgs/product/oa/tab3.png';
import tab4 from '@/assets/imgs/product/oa/tab4.png';
import icon1 from '@/assets/imgs/product/oa/icon1.png';
import icon2 from '@/assets/imgs/product/oa/icon2.png';
import icon3 from '@/assets/imgs/product/oa/icon3.png';
import icon4 from '@/assets/imgs/product/oa/icon4.png';
import icon5 from '@/assets/imgs/product/oa/icon5.png';
import icon6 from '@/assets/imgs/product/oa/icon6.png';
import icon7 from '@/assets/imgs/product/oa/icon7.png';
import icon8 from '@/assets/imgs/product/oa/icon8.png';
import icon9 from '@/assets/imgs/product/oa/icon9.png';
import icon10 from '@/assets/imgs/product/oa/icon10.png';
import icon11 from '@/assets/imgs/product/oa/icon11.png';
import icon12 from '@/assets/imgs/product/oa/icon12.png';
import icon13 from '@/assets/imgs/product/oa/icon13.png';
import icon14 from '@/assets/imgs/product/oa/icon14.png';
import icon15 from '@/assets/imgs/product/oa/icon15.png';
import icon16 from '@/assets/imgs/product/oa/icon16.png';
import icon17 from '@/assets/imgs/product/oa/icon17.png';
import icon18 from '@/assets/imgs/product/oa/icon18.png';
import icon19 from '@/assets/imgs/product/oa/icon19.png';
import icon20 from '@/assets/imgs/product/oa/icon20.png';
import icon21 from '@/assets/imgs/product/oa/icon21.png';
import kuozhan1 from '@/assets/imgs/product/oa/kuozhan1.png';
import kuozhan2 from '@/assets/imgs/product/oa/kuozhan2.png';
import kuozhan3 from '@/assets/imgs/product/oa/kuozhan3.png';
import kuozhan4 from '@/assets/imgs/product/oa/kuozhan4.png';
import kuozhan5 from '@/assets/imgs/product/oa/kuozhan5.png';
import kuozhan6 from '@/assets/imgs/product/oa/kuozhan6.png';
import kuozhan7 from '@/assets/imgs/product/oa/kuozhan7.png';
import btn from '@/components/btn.vue';

export default {
  name: 'ProOaPage',
  components: {
    Header, Footer, btn,
  },
  data() {
    return {
      oa_bg,
      fs: [
        {
          text: '无纸化办公',
          img: oa1,
        },
        {
          text: '提高协同办公效率',
          img: oa2,
        },
        {
          text: '日常审批高效',
          img: oa3,
        },
        {
          text: '加强企业规范管理',
          img: oa4,
        },
        {
          text: '统一工作入口',
          img: oa5,
        },
      ],
      swiper: [tab1, tab2, tab3, tab4],
      icon: [],
      namelist: ['请假申请', '加班申请', '外出申请', '出差申请', '借款申请', '用车申请', '酒店申请', '名片申请', '用章申请', '招聘申请', '采购申请', '礼品申请', '招待申请', '外勤申请', '日志', '备忘录', '日程管理', '通讯录', '交办事务', '考勤打卡'],
      iconlist: [icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8, icon9, icon10, icon11, icon12, icon13, icon14, icon15, icon16, icon17, icon18, icon19, icon20, icon21],
      kuozhan: [
        {
          title: '多语言包',
          subtitle: '可提供：中文繁体、英文、日文、韩文、更可扩展支持更多语种。',
          img: kuozhan1,
        },
        {
          title: '手机App PAD App',
          subtitle: '支持Android及iOS系统，包括工作流、华聊、会议、考勤等14个模块',
          img: kuozhan2,
        },
        {
          title: '微信平台',
          subtitle: '基于微信企业号，在微信中实现快速审批、消息阅读等众多功能。',
          img: kuozhan3,
        },
        {
          title: 'OA助手',
          subtitle: '无需登录浏览器，打开电脑就能接收OA信息。像QQ一样工作',
          img: kuozhan4,
        },
        {
          title: 'HR',
          subtitle: '考勤排班倒班、绩效考核系统、在线考试系统',
          img: kuozhan5,
        },
        {
          title: '手机短信',
          subtitle: '支持各种短信通道，系统信息自动发送，支持群发',
          img: kuozhan6,
        },
        {
          title: '中间件',
          subtitle: '多系统数据整合、公文传输平台、全文批注、钉钉整合',
          img: kuozhan7,
        },
      ],
    };
  },
  mounted() {
    for (let i = 0; i < this.iconlist.length; i++) {
      const obj = {
        icon: this.iconlist[i],
        text: this.namelist[i],
      };
      this.icon.push(obj);
    }
  },
  methods: {
    openqq() {
      window.open('http://shang.qq.com/wpa/qunwpa?idkey=3d0bc04e6a1e43055b8e69fd8324c477a45fa1cba57cfcd03dc95ba61427c52c');
    },
    openBut() {
      const self = this;
      self.$store.commit('addActChange', false);
      self.$store.commit('moreActChange', false);
      self.$store.commit('winActChange', true);
    },
  },
};
</script>

<style lang="less">
.product_oa_content {
  .box_top {
    width: 100%;
    height: 600px;
    background:linear-gradient(270deg,rgba(255,125,80,1) 0%,rgba(246,71,77,1) 100%);
    display: flex;
    justify-content: space-between;
    .top_tlite {
      padding: 160px 0 0 195px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      > p {
        padding-left: 180px;
      }
      > .title {
        font-size: 60px;
        margin: 0;
        text-align: left;
      }
      > .subtitle {
        font-size: 60px;
        margin: 20px 0 70px;
      }
      > .btn {
        text-align: center;
        box-sizing: border-box;
        margin-left: 180px;
        width:200px;
        height:60px;
        line-height: 60px;
        border:2px solid rgba(255,255,255,1);
        background:linear-gradient(90deg,rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
        border-radius:6px;
        cursor: pointer;
      }
    }
    .top_img {
      display: flex;
      align-items: flex-end;
      img {
        width: 752px;
        height: 547px;
        position: relative;
        left: -305px;
      }
    }
  }
  .page_content {
    .section {
      text-align: center;
      margin:0 auto;
      padding: 120px 0 100px;
      &.blue {
        background: #F7FBFF;
      }
      &.linear {
        background:linear-gradient(0deg,rgba(255,255,255,1) 0%,rgba(255,239,235,1) 100%);
      }
      .title {
        font-size: 40px;
        color: #000000;
        text-align: center;
        font-weight:bold;
        margin-bottom: 80px;
      }
      .subtitle {
        font-size: 20px;
        color: #303336;
        text-align: center;
        margin: 80px auto 100px;
        width: 1200px;
        &.short {
          width: 880px;
          font-size: 16px;
          color: #000000;
          margin-bottom: 60px;
        }
      }
      .content_box {
        width: 1200px;
        margin: 0 auto;
        &.swiper {
          width: 940px;
        }
        .fs {
          display: flex;
          align-items: center;
          justify-content: center;
          > div {
            width: 195px;
            &:not(:first-child) {
              margin-left: 85px;
            }
            > img {
              width: 160px;
              display: block;
              margin: 0 auto;
            }
            > p {
              color: #333333;
              width: 195px;
              margin: 0;
              margin-top: 25px;
              font-weight: bold;
            }
          }
        }
        .icons {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          .items {
            background:rgba(255,255,255,1);
            border-radius:4px;
            box-shadow: 0px 0px 5px rgba(0,0,0,.1);
            width: 150px;
            padding: 40px 0 30px;
            margin: 10px;
            > img {
              width: 70px;
              height: 70px;
              // padding: 40px;
            }
            > p {
              height: 28px;
              margin: 0;
              margin-top: 5px;
              font-size: 20px;
              color: #666666;
            }
          }
        }
        .kuozhan {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          > div {
            width: 20%;
            padding: 0 30px;
            margin-bottom: 65px;
            > img {
              width: 80px;
              margin: 0 auto;
            }
            .title {
              font-weight: bold;
              font-size: 24px;
              color: #000000;
              margin: 25px 0 30px;
              width: 100%;
            }
            .subtitle {
              width: 100%;
              font-size: 18px;
              color: #999999;
              margin: 0;
            }
          }
        }
        .ljsq {
          margin-top: 95px;
          width: 240px;
          height: 60px;
          line-height: 60px;
        }
      }
    }
  }
  .el-carousel__indicators.el-carousel__indicators--horizontal.el-carousel__indicators--outside {
    margin-top: 40px;
  }
  .el-carousel__indicator.el-carousel__indicator--horizontal.is-active {
    > button {
      background: #FF3041;
    }
  }
  .swiper {
    .el-carousel__container {
      height: 600px;
    }
  }
}
</style>
